<template>
    <div style="text-align: left">
      <el-button
        type="primary"
        class="z-depth-0"
        @click="showAddPackageModal"
      >Choose Package</el-button>
  
      <article>
        <section>
          <div class="experience_package_dialogs">
            <el-dialog
              :title="`Choose Package`"
              :visible.sync="addPackageModal"
              :destroy-on-close="true"
              :before-close="handleClose"
              style="text-align: left"
            >
              <div v-loading="isLoading">
                <el-form
                  :model="packageForm"
                  :rules="packageRules"
                  ref="packageForm"
                  class="demo-packageForm2"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item prop="packageIds">
                        <el-select
                          v-model="packageForm.packageIds"
                          placeholder="Choose Package"
                          multiple="true"
                          style="width: 100%;"
                        >
                          <el-option
                            v-for="tour in safari_tours"
                            :key="tour.id"
                            :label="tour.title"
                            :value="tour.id"
                          ></el-option>
                        </el-select>
  
                        <el-form-item class="text-center mt-3">
                          <el-button
                            type="primary"
                            @click="addPackage('packageForm')"
                          >Add Package(s)</el-button>
                        </el-form-item>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
  <br>
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2023 Ihamba Adventures | All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!--<small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </el-dialog>
          </div>
        </section>
      </article>
    </div>
  </template>
  
  
  <script>
  export default {
    data() {
      return {
        addPackageModal: false,
        selectedFile: null,
        isLoading: false,
        value: "",
        loading: false,
        loadingError: false,
        imageUploadingProgress: 0,
        progressColors: [
          { color: "#f56c6c", percentage: 25 },
          { color: "#e6a23c", percentage: 50 },
          { color: "#1989fa", percentage: 75 },
          { color: "#6f7ad3", percentage: 100 },
        ],
        packageForm: {
          packageIds: [],
        },
        fileLists: [],
        safari_tours: [],
  
        packageRules: {
          packageIds: [
            {
              required: true,
              message: "Packages is required",
              trigger: "change",
            },
          ],
        },
      };
    },
  
    props: {
      blogId: {
        required: true,
        type: String,
      },
    },
  
    methods: {
      handleClose(done) {
        done();
      },
  
      handlePreview(file) {
        console.log(file.raw);
        this.fileLists = [file];
        this.selectedFile = file.raw;
      },
      handleRemove() {
        this.selectedFile = null;
      },
  
      async addPackage(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.isLoading = true;
           
            try {
              this.isLoading = true;
              let request = await this.$http.post(
                `/safari_tours/add_blog_package`,
                {
                  blog_id: this.blogId,
                  packages: this.packageForm.packageIds,
                }
              );
              if (
                request.data.success &&
                request.data.message == "ADDED_SUCCESSFULY"
              ) {
                this.addPackageModal = false;
                this.$emit("refreshPackages");
                this.$notify({
                  title: "Success",
                  message: "Package(s) Attached Successfully",
                  type: "success",
                });
              } else throw "UNEXPECTED_RESPONSE";
            } catch (error) {
              if (error.message == "NetworkError") {
                this.isLoading = false;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred please try again",
                  type: "warning",
                });
              }
              this.isLoading = false;
              this.$notify({
                title: "Upload Failed",
                message: "Unable to attach package now, Please try again",
                type: "error",
              });
            } finally {
              this.isLoading = false;
            }
          } else {
            return false;
          }
        });
      },
  
      async getSafariPackages() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
          let request = await this.$http.get(`api/safari/package/get_all/admin`);
          if (request.data.success) {
            // this.lecturerCourseUnits = request.data.lecturer_course_units;
            this.safari_tours = request.data.safari_tours;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch Safari Packages now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      showAddPackageModal() {
        this.addPackageModal = true;
        this.getSafariPackages();
      },
    },
  };
  </script>
  
  <style scoped>
  input[type="text"],
  select,
  textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #ffffff;
    font-size: 0.9em;
  }
  
  .search_add_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .search_by_input {
    width: 400px !important;
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
    .search_by_input {
      width: 100% !important;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .search_add_section {
      display: block;
    }
    .addbtn {
      width: 100%;
      margin-top: 10px;
    }
    .search_by_input {
      width: 100% !important;
    }
  }
  </style>
  
  <style>
  .experience_package_dialogs .el-dialog {
    width: 40%;
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .experience_package_dialogs .el-dialog {
      width: 60%;
    }
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .experience_package_dialogs .el-dialog {
      width: 70%;
    }
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .experience_package_dialogs .el-dialog {
      width: 90%;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .experience_package_dialogs .el-dialog {
      width: 90%;
    }
  }
  </style>
  